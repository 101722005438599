
import { getSiteLanguage } from '@utils'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import useSWR from 'swr'
import StateContext from '../ContextProvider/Context'
import { News } from '@services'
import getNewsAPIParams from '../utils/getNewsAPIParams'
import prepareDropdown from '../utils/prepareDropdown'
import defineLimit from '../utils/defineLimit'
import axios, { CancelTokenSource } from 'axios'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
const CancelToken = axios.CancelToken

const useInitNews = () => {
  const { labels: apiLabels = {} } = useLabelsFromAPI(['AltTags'], getSiteLanguage())

  // Source is tech stuff to cancel the request when there's another request coming
  const source = useRef<CancelTokenSource | null>(null)
  const [filtersAsString, setFiltersAsString] = useState('')
  const { state: { labels, randomStr, selectedCategory,
    selectedProduct, news, initialized, layout }, refs, dispatch
  } = useContext(StateContext)
  const isInfiniteLayout = layout === '2n' || layout === '3n'
  const defaultFilterItem = useMemo(
    () => {
      const defaultParams = { key: 0, value: 0, label: labels.news_grp1_all_lbl || 'All' }
      return defaultParams
    }, [labels.news_grp1_all_lbl])

  // Put functions in Context refs, so I can call from everywhere, not depending on this Hook
  // @ts-ignore
  refs.handleChangeCategory.current = async (value) => {
    dispatch({ type: 'SET_SELECTED_CATEGORY', payload: value })
    resetPagination()
  }

  // @ts-ignore
  refs.handleChangeProduct.current = async (value) => {
    dispatch({ type: 'SET_SELECTED_PRODUCT', payload: value })
    resetPagination()
  }

  const resetPagination = () => {
    setFiltersAsString(`${selectedCategory.toString()}${selectedProduct.toString()}`)
    // @ts-ignore no idea how to type this simple ref.
    refs.offset.current = 0
    // @ts-ignore type later
    refs.paginationInfo.current.pageToken = null
  }

  const { data: categories } = useSWR<ABTypes.Cmscategories.ResponseListCategoryDTO & { items: ABTypes.Cmscategories.CategoryDTO[] }>(`/msvc/v1/cmscategories?locale=${getSiteLanguage()}`, null, {
    revalidateOnFocus: false
  })
  const { data: products } = useSWR<ABTypes.Cmsproducts.ResponseListProductDTO & { items: ABTypes.Cmsproducts.ProductDTO[] }>(`/msvc/v1/cmsproducts?locale=${getSiteLanguage()}`, null, {
    revalidateOnFocus: false
  })
  const firstLimit = defineLimit(labels.news_grp1_article)
  const secondLimit = 27

  // Every time News change, update store
  const fetch = useCallback(() => {
    const newsParams = getNewsAPIParams({
      labels,
      selectedCategory,
      selectedProduct,
      randomStr,
      firstLimit,
      secondLimit,
      offset: refs.offset?.current,
      // @ts-ignore
      paginateToken: refs.paginationInfo.current?.pageToken,
      locale: getSiteLanguage()
    })
    const fetchNews = async () => {
      if (source.current) {
        source.current.cancel()
      }
      source.current = CancelToken.source()
      dispatch({ type: 'SET_ISLOADING', payload: true })
      const result = await News.search(newsParams, { cancelToken: source.current.token })
      if (!result.data?.items) return null
      // @ts-ignore
      const accumulatedNewsItems = refs.offset.current == 0 ? result.data?.items : [...news, ...result.data?.items]
      dispatch({ type: 'SET_ISLOADING', payload: false })
      dispatch({ type: 'SET_NEWS', payload: accumulatedNewsItems })
      dispatch({ type: 'HAS_NEWS', payload: typeof news === 'object' ?? accumulatedNewsItems.length > 0 })
      // Put all the pagination stuff in a ref, for easy access. Except the items list.
      // @ts-ignore
      refs.paginationInfo.current = _.omit(result.data, 'items')
    }
    fetchNews()
  }, [dispatch, firstLimit, labels, news, randomStr, refs.offset, refs.paginationInfo,
    selectedCategory, selectedProduct])

  // Initial news fetch
  useEffect(() => {
    !initialized && isInfiniteLayout && fetch()
    dispatch({ type: 'IS_INITIALIZED', payload: true })
  }, [dispatch, fetch, initialized, isInfiniteLayout])

  const filtersAsStringRef = useRef('')

  // Detects changes in the filters values, and triggers fetch if needed
  useEffect(() => {
    if (filtersAsStringRef.current != filtersAsString) {
      fetch()
      filtersAsStringRef.current = filtersAsString
    }
  }, [fetch, filtersAsString, refs.loadNextPage])

  // @ts-ignore Idk how to type this yet
  refs.loadNextPage.current = fetch
  // Setting dropdown Filters values in store
  useEffect(() => {
    if (typeof categories !== undefined && typeof products !== undefined) {
      dispatch({
        type: 'SET_FILTERS', payload: {
          categories: prepareDropdown(categories?.items, defaultFilterItem),
          products: prepareDropdown(products?.items, defaultFilterItem)
        }
      })
    }
  }, [categories, defaultFilterItem, dispatch, products])

  const displayVideoIcon = (items) => items?.find(i => i?.name?.toLowerCase()?.includes('videoicon')) || false

  return { displayVideoIcon, isInfiniteLayout, apiLabels }
}
export default useInitNews