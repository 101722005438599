import { orderBy } from 'lodash' // For UI prototyping purposes

const prepareDropdown = (items, defaultFilterItem) => {
  let itemsOutput = orderBy(items, ['displayOrder'], ['asc'])
  itemsOutput = itemsOutput.map(item => ({
    key: item.id,
    value: item.id.toString(),
    label: item.name,
    name: item.id
  }))
  itemsOutput = itemsOutput.filter(item => item.label !== undefined && item.label !== '')
  itemsOutput = orderBy(itemsOutput, ['displayOrder'], ['asc'])
  itemsOutput.unshift(defaultFilterItem)
  return itemsOutput
}

export default prepareDropdown