import { FC, useContext } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { Container, Row, Col } from '@mch-group/uikit-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'

import { cloudinary, date } from '@constants'
import { formatDate, formatArticleLink, detectDpr } from '@utils'
import { CustomLinkTag, CloudinaryImage } from '@components'
import CallToAction from '@reusable-modules/common/CallToAction'
import StateContext from './ContextProvider/Context'
import { Media, MediaContextProvider } from '@utils/createMedia'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import useInitNews from './hooks/useInitNews'
// mID is repeated in Context but it's necessary here to have in SC.
const mID = 'rm-news__article'

const NewsArticle: FC<INewsArticles> = ({ className }) => {
  const {
    state: { labels, news }
  } = useContext(StateContext)
  const { apiLabels } = useInitNews()
  const item = news[0]
  const type = 'hero'
  const articleLink = item.redirectUrl ? item.redirectUrl : formatArticleLink(item.url)
  const heroImageURL = `${item.featureImageUrl !== '' && type === 'hero' ? item.featureImageUrl : item.imageUrl}`
  const aspect_ratio = type === 'hero' ? '25:7' : '16:9'
  return (
    <article className={className}>
      {/* Will write a class alike rm-news__article--hero or rm-news__article--block */}
      <div className={classNames(mID, `${mID}--${type}`)}>
        <div className={`${mID}-banner`}>
          <MediaContextProvider>
            <Media greaterThanOrEqual='computer'>
              <CustomLinkTag
                url={articleLink}
                extraParams={{ cleanTitle: item.url }}
                page='/'
                title={`${apiLabels?.AltTags?.generalReadMoreAbout || 'Read more about'} ${item.title}`}
              >
                {item.imageUrl && item.imageUrl !== '' && (
                  <img
                    src={uploadTransformationUrl(
                      heroImageURL,
                      `ar_${aspect_ratio},${detectDpr()},q_auto,f_auto,c_fill,w_2545,fl_progressive`,
                      { transformUrl: cloudinary.throughCloudfront }
                    )}
                    alt={`${apiLabels?.AltTags?.generalReadMoreAbout || 'Read more about'} ${item.title}`}
                  />
                )}
              </CustomLinkTag>
            </Media>
            <Media lessThan='computer'>
              <figure>
                <CustomLinkTag url={articleLink} extraParams={{ cleanTitle: item.url }} page='/'>
                  {item.imageUrl && item.imageUrl !== '' && (
                    <CloudinaryImage
                      publicId={item.imageUrl}
                      crop='fill'
                      aspect_ratio='8:5'
                      alt={`${apiLabels?.AltTags?.generalReadMoreAbout || 'Read more about'} ${item.title}`}
                      responsive
                      dpr={detectDpr('dpr_')}
                    />
                  )}
                  {/* Todo MCHGB-5054  need to add label for "by" for CloudinaryImage. */}
                </CustomLinkTag>
              </figure>
            </Media>
          </MediaContextProvider>
        </div>
        <Container
          className={`px-5 px-lg-8 px-md-7 col-md-12 mx-0 mx-lg-auto ${type === 'hero' ? '' : 'no-horizontal-padding'}`}
        >
          <Row>
            <Col md={type === 'hero' ? 8 : 12} xs={12}>
              {type === 'hero' && labels.news_grp1_title && (
                <Row className='mb-5'>
                  <div className='h4'>{labels.news_grp1_title}</div>
                </Row>
              )}
              <section>
                {(item.categoryName || item.productName) && (
                  <div className={`${mID}-category`}>
                    {item.categoryName && labels.news_grp1_flag_category === 'true' && item.categoryName && (
                      <span>{item.categoryName}</span>
                    )}
                    {item.productName && labels.news_grp1_flag_product === 'true' && (
                      <span>{item.productName + (item.subProduct ? `:  ${item.subProduct} ` : '')}</span>
                    )}
                  </div>
                )}
                {labels.news_grp1_flag_title === 'true' && (
                  <CustomLinkTag url={articleLink} extraParams={{ cleanTitle: item.url }} page='/'>
                    {type === 'hero' ? (
                      <h1 className={`${mID}__title h2`}>{item.title && <span>{item.title}</span>}</h1>
                    ) : (
                      <h4 className={`${mID}__title`}>{item.title && <span>{item.title}</span>}</h4>
                    )}
                  </CustomLinkTag>
                )}
                {(labels.news_grp1_flag_author === 'true' || labels.news_grp1_flag_publish_date === 'true') && (
                  <div className={`${mID}-author-date`}>
                    {labels.news_grp1_flag_author === 'true' && item.authorName && (
                      <span className='soft-text'>{item.authorName}</span>
                    )}
                    {labels.news_grp1_flag_publish_date === 'true' && item.publishedDate && (
                      <span className='soft-text'>
                        {formatDate(item.publishedDate, 'YYYY-MM-DD', date.FE_WEEK_DATE_FORMAT)}
                      </span>
                    )}
                  </div>
                )}
                <div className={`${mID}-excerpt ${type === 'hero' ? '' : null}`}>
                  {item.description && labels.news_grp1_flag_description === 'true' && (
                    <CustomLinkTag url={articleLink} extraParams={{ cleanTitle: item.url }} page='/'>
                      <span>{item.description}</span>
                    </CustomLinkTag>
                  )}
                </div>
                {labels.news_grp1_label_call_to_action && (
                  <CallToAction
                    arrowFloatedRight
                    href={item.redirectUrl ? item.redirectUrl : item.url}
                    hrefAs={articleLink}
                    title={`${apiLabels?.AltTags?.generalReadMoreAbout || 'Read more about'} ${item.title}`}
                    as={item.redirectUrl ? item.redirectUrl : item.url}
                  >
                    {labels.news_grp1_label_call_to_action}
                  </CallToAction>
                )}
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </article>
  )
}

const NewsArticleStyled = styled(NewsArticle)`
  height: 100%;

  .${mID} {
    &__title {
      margin-top: -7px;
      margin-bottom: 20px;
    }

    display: flex;
    flex-direction: column;
    height: 100%;

    &--stretch {
      display: flex !important;
      flex: 1 0 auto;
      flex-direction: column;
    }

    &-banner {
      position: relative;
      margin: 0 0 16px;

      @media (max-width: ${global.uikitVars.breakpointMdMax}) {
        padding-bottom: 62.5%;
      }

      @media (min-width: ${global.uikitVars.breakpointLg}) {
        padding-bottom: 56.25%;
      }

      figure {
        margin: 0;
      }

      img {
        position: absolute;
        max-width: 100%;
        width: 100%;
      }
    }

    &--hero {
      margin-bottom: 32px;

      .${mID}-banner {
        @media (min-width: ${global.uikitVars.breakpointLg}) {
          margin: 0 0 40px;
          padding-bottom: 28%;
        }
      }
    }

    &--block {
      .ui.container {
        @media (max-width: ${global.uikitVars.breakpointMdMax}) {
          margin: 0 !important;
        }
      }
    }

    &-category {
      margin-bottom: 8px;
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value} !important;
    }

    &-author-date {
      margin-top: 8px;
      font-size: ${tokens.fontSize[1].value};
    }

    &-excerpt {
      margin-top: 16px;
      margin-bottom: 32px;
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }

    &-category,
    &-author-date {
      span + span {
        &::before {
          content: ' - ';
        }
      }
    }

    h4.ui.header {
      word-break: break-word;
    }

    .call-to-action {
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value} !important;
    }

    .soft-text {
      color: ${tokens.color.light.base.neutrals['700'].value};
    }
  }
`

interface INewsArticles {
  className?: string
}

export default NewsArticleStyled
