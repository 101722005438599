import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { px2rem, hiDPIpx } from '../utils'
import mq from '../utils/styled-mq'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const NoResultsWrapper = styled.section`
  margin-top: ${px2rem(62)};
  ${mq.between('tablet', 'largestTablet')`
    font-size: ${hiDPIpx(55)};
  `}
  ${mq.lessThan('largestMobile')`
    font-size: ${tokens.fontSize[14].value};
  `}

  .main-text {
    ${mq.lessThan('largestTablet')`
    
      ${props =>
      // @ts-ignore
      props.isNested && 'margin: 0 !important;'
    }
    `}

    div {
      font-size: ${tokens.fontSize[5].value};
      ${mq.between('tablet', 'largestTablet')`
        font-size: ${hiDPIpx(20)};
      `}
      ${mq.lessThan('largestMobile')`
        font-size: ${tokens.fontSize[4].value};
      `}
    }
  }
`

const NoResultsComponent = ({ isNested }) => (
  // @ts-ignore
  <NoResultsWrapper isNested={isNested}>
    No results found
  </NoResultsWrapper>
)

NoResultsComponent.propTypes = {
  className: PropTypes.string,
  entityid: PropTypes.string,
  fieldName: PropTypes.string,
  labels: PropTypes.object,
  customText: PropTypes.string,
  isNested: PropTypes.bool
}

export default connect(
  ({ shared }) =>
  ({
    labels: shared.labels
  }),
  null
)(NoResultsComponent)
